import React from 'react';
import PageLayout from '@containers/PageLayout';

const ContactPage = () => (
  <PageLayout title="Contact">
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="grid grid-cols-1 gap-16">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
              Get in touch
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              <div>
                <h4 className="text-lg leading-6 font-medium text-gray-900">
                  Say hello
                </h4>
                <dl className="mt-2 text-base leading-6 text-gray-500">
                  <div>
                    <a
                      href="mailto:hello@stevensajja.com"
                      rel="noreferrer"
                      target="_blank"
                    >
                      hello@stevensajja.com
                    </a>
                  </div>
                  <div className="mt-1">
                    <a
                      href="https://calendly.com/steven-sajja/30min"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Book time with me
                    </a>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div className="border-t-2 border-gray-100" />
        </div>
      </div>
    </div>
  </PageLayout>
);

export default ContactPage;
